import React, { Component } from "react";
import { Row, Col, Container, Modal } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import Auth from "../Auth";
import axios from "axios";
import Navbar from "./Navbar";
import SignOut from "./SignOut";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
export class InactiveApplicants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      applicants: [],
      selectedInt: [],
      openDelete: false,
      openSucess: false,
      fields: { res1: "", res2: "" },
      currentModalIndex: null,
      selectedApp: [],
      dataOut: 10,
      searchItem: "",
      filters: {
        datefrom: "",
        dateTo: "",
      },

      startDate: undefined,
      endDate: undefined,
    };
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
  }
  handleChangeDate(date) {
    this.setState({
      startDate: new Date(date),
    });

    let filters = this.state.filters;
    if (filters == "") {
      filters.datefrom = format(Date.parse(date), "yyyy-MM-dd HH:mm:ss");
      this.setState({
        filters: filters,
      });
    } else
      this.setState({
        filters: this.state.startDate,
      });

    console.log(this.state.filters);
  }
  handleChangeEndDate(date) {
    this.setState({
      endDate: new Date(date),
    });

    let filters = this.state.filters;
    if (filters == "") {
      filters.dateTo = format(Date.parse(date), "yyyy-MM-dd HH:mm:ss");
      this.setState({
        filters: filters,
      });
    } else
      this.setState({
        filters: this.state.endDate,
      });

    console.log(this.state.filters);
  }
  openModalDelete = (intsel, i) => {
    this.setState({
      selectedInt: intsel,
      openDelete: true,
      fields: { res1: intsel.FirstName, res2: intsel.LastName },
      currentModalIndex: i,
    });
  };
  closeDelete = () => {
    this.setState({
      openDelete: false,
    });
  };
  openModalSucess = (intsel, i) => {
    this.setState({
      selectedInt: intsel,
      openSucess: true,
      fields: { res1: intsel.FirstName, res2: intsel.LastName },
      currentModalIndex: i,
    });
  };
  closeSuucess = () => {
    this.setState({
      openSucess: false,
    });
  };
  componentDidMount = () => {
    this.getJobs();
  };

  getJobs = () => {
    var token = localStorage.getItem("token");
    var config = {
      method: "get",
      url: "https://api.obsessedwithcapital.com/api/users/",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        this.setState({
          applicants: response.data
            .filter((item) => !item.active)
            .sort((a, b) => (new Date(a) < new Date(b) ? 1 : -1)),
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  submitHandler = () => {
    let interSelId = this.state.selectedInt._id;
    console.log(interSelId);
    var token = localStorage.getItem("token");
    var config = {
      method: "delete",
      url: "https://api.obsessedwithcapital.com/api/users/delete/" + interSelId,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        window.location.href = window.location.origin + "/Main/Aplications";
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleOpen = (items, i) => {
    this.setState({
      currentModalIndex: i,
    });
    var token = localStorage.getItem("token");
    var config = {
      method: "put",
      url:
        "https://api.obsessedwithcapital.com/api/users/activate/" + items._id,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        alert("you have successfully activated the user");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  activate = (id, items) => {
    if (this.state.applicants.active != true) {
      return (
        <p className="viewdetclion" onClick={() => this.handleOpen(id, items)}>
          Activate
        </p>
      );
    }
  };
  handleUserChange = (e) => {
    this.setState({
      searchItem: e.target.value,
    });
  };
  // Remove = () => {
  //   if ((this.state.applicants.active = false)) {
  //     return <p className="viewdetclion ">Remove</p>;
  //   }
  // };
  loadmore = () => {
    this.setState((newdata) => {
      return {
        dataOut: newdata.dataOut + 10,
      };
    });
  };
  render() {
    const { applicants } = this.state;
    console.log(applicants);
    const dateFilter =
      this.state.startDate != undefined
        ? format(Date.parse(this.state.startDate), "yyyy-MM-dd")
        : "";
    const dateFilterEnd =
      this.state.endDate != undefined
        ? format(Date.parse(this.state.endDate), "yyyy-MM-dd")
        : "";
    const resultDate =
      applicants &&
      applicants.filter((ite) => {
        return (
          ((ite.date.substring(0, 10) >= dateFilter && dateFilter != "") ||
            dateFilter === "") &&
          ((ite.date.substring(0, 10) <= dateFilterEnd &&
            dateFilterEnd != "") ||
            dateFilterEnd === "")
        );
      });
    return (
      <div>
        <div className="contentdashbord">
          <Navbar />
          <Container fluid>
            <Row>
              <Col md-10></Col>

              <Col md-2 className="d-flex justify-content-end rowpush">
                <div className="search ">
                  <div className="header-items d-flex align-items-center pt-2">
                    <div className="d-flex mt-5">
                      <p className="d-flex ">
                        <div className="conteinpu  mt-2">Date From</div>
                        <div className=" parainputsapphf ">
                          <DatePicker
                            selected={this.state.startDate}
                            //onChange={(e) => this.handleChangeDate(e)}
                            onChange={this.handleChangeDate}
                            name="transactionDate"
                            className="loginsearchfa"
                            dateFormat="yyyy-MM-dd"
                            placeholder="Pick a date"
                            id="validationDefault01"
                            required
                          />
                        </div>
                      </p>
                      <p className="d-flex ">
                        <div className="conteinpu mt-2">Date To</div>
                        <div className=" parainputsapphf ">
                          <DatePicker
                            selected={this.state.endDate}
                            //onChange={(e) => this.handleChangeDate(e)}
                            onChange={this.handleChangeEndDate}
                            name="transactionDate"
                            className="loginsearchfa"
                            dateFormat="yyyy-MM-dd"
                            placeholder="Pick a date"
                            id="validationDefault01"
                            required
                          />
                        </div>
                      </p>
                      <input
                        id="search"
                        type="text"
                        className="loginsearchfa"
                        placeholder="Search here ..."
                        onChange={(e) => this.handleUserChange(e)}
                      />
                    </div>
                  </div>
                </div>
                <SignOut />
              </Col>
            </Row>
            <br />
            <div className="rowup">
              <p className=" contentmemb">
                Inactive applicants: {applicants && applicants.length}
              </p>
              <div className=" cardtable">
                <div class="table-responsive">
                  <table class="table table-hover  table-responsive  ">
                    <thead>
                      <tr>
                        <th className="userprony ">Applicant Details</th>
                        <th className="userprony ">Email</th>
                        <th className="userprony ">phonenumber</th>
                        <th className="userprony ">Bank Name</th>
                        <th className="userprony ">Sort Code</th>
                        <th className="userprony ">Account Number</th>
                        <th className="userprony ">Date</th>
                        <th className="userprony "></th>
                        <th className="userprony "></th>
                      </tr>
                    </thead>
                    {dateFilter != undefined && dateFilterEnd != undefined ? (
                      <tbody>
                        {resultDate &&
                          resultDate
                            .filter((item) =>
                              item.FirstName?.toLowerCase().includes(
                                this.state.searchItem
                              )
                            )
                            .slice(0, this.state.dataOut)
                            .map((items, i) => {
                              return (
                                <tr>
                                  <td className="userpron ">
                                    {items.LastName} {items.FirstName}
                                  </td>
                                  <td className="userpron ">{items.email}</td>
                                  <td className="userpron ">
                                    {items.phonenumber}
                                  </td>
                                  <td className="userpron ">
                                    {items.bankname}
                                  </td>
                                  <td className="userpron ">
                                    {items.sortcode}
                                  </td>
                                  <td className="userpron ">
                                    {items.bankaccount}
                                  </td>
                                  <td className="userpron ">
                                    {items.date.toString().substring(0, 10)}
                                  </td>
                                  <td>
                                    {this.activate(items, items._id)}
                                    {/* {this.Remove()} */}
                                  </td>
                                  <td
                                    type="submit"
                                    className="viewdetclion"
                                    onClick={() =>
                                      this.openModalDelete(items, i)
                                    }
                                  >
                                    Delete
                                  </td>
                                  <div className="schoolModal">
                                    <Modal
                                      show={
                                        this.state.openDelete &&
                                        this.state.currentModalIndex == i
                                      }
                                    >
                                      <Modal.Body className="Madl">
                                        <div>
                                          <p
                                            className="closeModal"
                                            onClick={this.closeDelete}
                                          >
                                            <i className="fas fa-times"></i>
                                          </p>
                                        </div>
                                        <div>
                                          <p className="succpar">
                                            {" "}
                                            Hey Admin!{" "}
                                          </p>
                                          <p className="superball">
                                            Are you sure you want to delete the
                                            user!
                                          </p>
                                          <div>
                                            <div className="d-flex">
                                              <div className="d-flex">
                                                <label className="lebelenter">
                                                  First Name:
                                                </label>
                                                <input
                                                  className="formModalalt"
                                                  name="res1"
                                                  type="text"
                                                  onChange={(e) =>
                                                    this.handleChange(e)
                                                  }
                                                  value={this.state.fields.res1}
                                                />
                                              </div>

                                              <br />
                                              <div className="d-flex">
                                                <label className="lebelenter">
                                                  Last Name:
                                                </label>
                                                <input
                                                  className="formModalalt"
                                                  name="res2"
                                                  type="text"
                                                  onChange={(e) =>
                                                    this.handleChange(e)
                                                  }
                                                  value={this.state.fields.res2}
                                                />
                                              </div>
                                            </div>

                                            <br />
                                            <div className="d-block  ml-5">
                                              <div className="row">
                                                <div className="col-3"></div>
                                                <div className="col-6">
                                                  <button
                                                    className="viewdetcli"
                                                    method="post"
                                                    onClick={(e) =>
                                                      this.submitHandler(e)
                                                    }
                                                  >
                                                    Delete
                                                  </button>
                                                </div>
                                                <div className="col-1"></div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </Modal.Body>
                                    </Modal>
                                  </div>
                                  <div className="schoolModal">
                                    <Modal
                                      show={
                                        this.state.openSucess &&
                                        this.state.currentModalIndex == i
                                      }
                                      className="modalAll"
                                    >
                                      <Modal.Body className="Madl">
                                        <div>
                                          <p
                                            className="closeModal"
                                            onClick={this.closeSuucess}
                                          >
                                            <i className="fas fa-times"></i>
                                          </p>
                                        </div>
                                        <div>
                                          <p className="sent">
                                            Successfully Activated!
                                          </p>
                                          <p className="succpar">
                                            The applicant can now use the
                                            system.
                                          </p>
                                        </div>
                                      </Modal.Body>
                                    </Modal>
                                  </div>
                                </tr>
                              );
                            })}
                        {applicants &&
                        applicants.length <= this.state.dataOut ? (
                          ""
                        ) : (
                          <div className="buttoncenter">
                            {applicants &&
                            applicants.length >= this.state.dataOut ? (
                              <button
                                type="button"
                                className="btnbookrermore  "
                                onClick={() => this.loadmore()}
                              >
                                <span className="mr-2 mt-1">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                                <span>Load More</span>
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        )}
                      </tbody>
                    ) : (
                      <tbody>
                        {applicants &&
                          applicants
                            .filter((item) =>
                              item.FirstName?.toLowerCase().includes(
                                this.state.searchItem
                              )
                            )
                            .slice(0, this.state.dataOut)
                            .map((items, i) => {
                              return (
                                <tr>
                                  <td className="userpron ">
                                    {items.LastName} {items.FirstName}
                                  </td>
                                  <td className="userpron ">{items.email}</td>
                                  <td className="userpron ">
                                    {items.phonenumber}
                                  </td>
                                  <td className="userpron ">
                                    {items.bankname}
                                  </td>
                                  <td className="userpron ">
                                    {items.sortcode}
                                  </td>
                                  <td className="userpron ">
                                    {items.bankaccount}
                                  </td>
                                  <td className="userpron ">
                                    {items.date.toString().substring(0, 10)}
                                  </td>
                                  <td>
                                    {this.activate(items, items._id)}
                                    {/* {this.Remove()} */}
                                  </td>
                                  <td
                                    type="submit"
                                    className="viewdetclion"
                                    onClick={() =>
                                      this.openModalDelete(items, i)
                                    }
                                  >
                                    Delete
                                  </td>
                                  <div className="schoolModal">
                                    <Modal
                                      show={
                                        this.state.openDelete &&
                                        this.state.currentModalIndex == i
                                      }
                                    >
                                      <Modal.Body className="Madl">
                                        <div>
                                          <p
                                            className="closeModal"
                                            onClick={this.closeDelete}
                                          >
                                            <i className="fas fa-times"></i>
                                          </p>
                                        </div>
                                        <div>
                                          <p className="succpar">
                                            {" "}
                                            Hey Admin!{" "}
                                          </p>
                                          <p className="superball">
                                            Are you sure you want to delete the
                                            user!
                                          </p>
                                          <div>
                                            <div className="d-flex">
                                              <div className="d-flex">
                                                <label className="lebelenter">
                                                  First Name:
                                                </label>
                                                <input
                                                  className="formModalalt"
                                                  name="res1"
                                                  type="text"
                                                  onChange={(e) =>
                                                    this.handleChange(e)
                                                  }
                                                  value={this.state.fields.res1}
                                                />
                                              </div>

                                              <br />
                                              <div className="d-flex">
                                                <label className="lebelenter">
                                                  Last Name:
                                                </label>
                                                <input
                                                  className="formModalalt"
                                                  name="res2"
                                                  type="text"
                                                  onChange={(e) =>
                                                    this.handleChange(e)
                                                  }
                                                  value={this.state.fields.res2}
                                                />
                                              </div>
                                            </div>

                                            <br />
                                            <div className="d-block  ml-5">
                                              <div className="row">
                                                <div className="col-3"></div>
                                                <div className="col-6">
                                                  <button
                                                    className="viewdetcli"
                                                    method="post"
                                                    onClick={(e) =>
                                                      this.submitHandler(e)
                                                    }
                                                  >
                                                    Delete
                                                  </button>
                                                </div>
                                                <div className="col-1"></div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </Modal.Body>
                                    </Modal>
                                  </div>
                                  <div className="schoolModal">
                                    <Modal
                                      show={
                                        this.state.openSucess &&
                                        this.state.currentModalIndex == i
                                      }
                                      className="modalAll"
                                    >
                                      <Modal.Body className="Madl">
                                        <div>
                                          <p
                                            className="closeModal"
                                            onClick={this.closeSuucess}
                                          >
                                            <i className="fas fa-times"></i>
                                          </p>
                                        </div>
                                        <div>
                                          <p className="sent">
                                            Successfully Activated!
                                          </p>
                                          <p className="succpar">
                                            The applicant can now use the
                                            system.
                                          </p>
                                        </div>
                                      </Modal.Body>
                                    </Modal>
                                  </div>
                                </tr>
                              );
                            })}
                        {applicants &&
                        applicants.length <= this.state.dataOut ? (
                          ""
                        ) : (
                          <div className="buttoncenter">
                            {applicants &&
                            applicants.length >= this.state.dataOut ? (
                              <button
                                type="button"
                                className="btnbookrermore  "
                                onClick={() => this.loadmore()}
                              >
                                <span className="mr-2 mt-1">
                                  <i class="fas fa-chevron-down"></i>
                                </span>
                                <span>Load More</span>
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        )}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}

export default InactiveApplicants;
