import React from "react";
import { Line } from "react-chartjs-2";
import axios from "axios";
class ChartOver extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      results: [],
    };
  }

  componentDidMount = () => {
    this.getAllStat();
  };

  getAllStat() {
    var token = localStorage.getItem("token");
    var config = {
      method: "get",
      url: "https://api.obsessedwithcapital.com/api/users/",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        console.log(response.data);
        this.setState({
          results: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  render() {
    const { results } = this.state;
    console.log(results);
    var labels = [];
    var jobappact = [];
    var jobappinact = [];
    results &&
      results.map((item) => {
        Object.keys(item.active).map((key) => {
          if (key !== "content" && !labels.includes(key)) labels.push(key);
        });

        labels.sort();

        // labels.forEach((label, index) => {
        labels.forEach((label, index) => {
          //const stat = item.find((s) => s.day == label);
          // console.log(
          //   item.content +
          //     " => Item." +
          //     label +
          //     "= " +
          //     item[label] +
          //     ",  index=" +
          //     index
          // );
          if (item.active === true) jobappact[index] = item[label];
          if (item.active === false) jobappinact[index] = item[label];
        });
      });
    //});
    //console.log(paiddata);
    //console.log(topaydata);

    const config = {
      labels,

      datasets: [
        {
          label: " Active Jobs Applicants",
          data: jobappact,
          fill: true,
          backgroundColor: " #16977f",
        },
        {
          label: " Inactive Job applictants",
          data: jobappinact,
          fill: true,
          backgroundColor: "#DFE0EB",
        },
      ],
    };
    const legend = {
      display: true,
      position: "bottom",
      label: {
        fontColor: "#00A65A",
        fontSize: 10,
      },
    };
    const options = {
      scales: {
        yAxes: [
          {
            ticks: {
              suggestedMin: 1,
              suggestedMax: 5,
            },
          },
        ],
      },
    };

    return (
      <div className="chart">
        <Line data={config} legend={legend} options={options} />
      </div>
    );
  }
}

export default ChartOver;
