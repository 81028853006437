import React, { Component } from "react";
import { Row, Col, Container, Modal } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import Auth from "../Auth";
import Navbar from "./Navbar";
import axios from "axios";
import SignOut from "./SignOut";
export class Publish extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobs: [],
      openSucess: false,
      fields: { res1: "", res2: "" },
      currentModalIndex: null,
      selectedJob: [],
    };
  }
  openModalSucess = (jobsel, i) => {
    this.setState({
      selectedJob: jobsel,
      openSucess: true,
      fields: { res1: jobsel.title, res2: jobsel.BusinessName },
      currentModalIndex: i,
    });
  };
  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
  }
  closeSuucess = () => {
    this.setState({
      openSucess: false,
    });
  };
  handleClick = () => {
    window.location.href = window.location.origin + "/Main/Singin/";
  };
  handleClickBook = () => {
    window.location.href = window.location.origin + "/Main/Bookstaff/";
  };
  componentDidMount = () => {
    this.getJobs();
  };
  submitHandler = () => {
    let jobSelId = this.state.selectedJob._id;
    var token = localStorage.getItem("token");
    var config = {
      method: "delete",
      url: "https://api.obsessedwithcapital.com/api/jobs/delete/" + jobSelId,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getJobs = () => {
    var token = localStorage.getItem("token");
    var config = {
      method: "get",
      url: "https://api.obsessedwithcapital.com/api/jobs",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        this.setState({
          jobs: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleAdd = () => {
    window.location.href = window.location.origin + "/Main/Addjob";
  };
  render() {
    const { jobs } = this.state;
    console.log(this.state.selectedJob._id);
    return (
      <div>
        <div className="contentdashbord">
          <Navbar />
          <Container fluid>
            <Row>
              <Col md-10></Col>

              <Col md-2 className="d-flex justify-content-end rowpush">
                <div className="search ">
                  <div className="header-items d-flex align-items-center pt-2">
                    <div className="ml-5">
                      <input
                        id="search"
                        type="text"
                        className="loginsearchfa"
                        placeholder="Search here ..."
                        onChange={(e) => this.handleUserChange(e)}
                      />
                      {/* <i
                        className="fas fa-search"
                        // onClick={this.searchHandle}
                      ></i> */}
                    </div>
                  </div>
                </div>
                <SignOut />
              </Col>
            </Row>
            <br />
            <div className="btnaddblog" onClick={this.handleAdd}>
              <i class="fas fa-plus"></i>
            </div>
            <br />
            <div className="row  mr-3">
              {jobs &&
                jobs.map((items, i) => {
                  return (
                    <>
                      <div className="col-xl-4 col-md-6 ">
                        <div className="card  m-b-30 cardworklistwork">
                          <div className="profile-headerstu">
                            <span>
                              <img
                                src={items.jobImage}
                                className="userprofile"
                              />
                            </span>
                            <span className="dataprofile">
                              <p className="userproname ">{items.title}</p>
                              <p className="userpro">{items.BusinessName}</p>
                            </span>
                          </div>
                          <div className="categoryhah d-flex mb-5 mt-5">
                            <div className="contper">
                              <div className="d-block">
                                <p className="descr">Person</p>
                                <p className="nuber">{items.numberofstaff}</p>
                              </div>

                              {/* <div className="linehorizontal" /> */}
                            </div>
                            <div className="contsal">
                              <div className="d-block">
                                <p className="descr">Salary</p>
                                <p className="nuber">${items.salary}/Mth +</p>
                              </div>

                              <div className="linehorizontal" />
                            </div>
                          </div>
                          <hr className="linecomplist" />
                          <div className="row">
                            <div className="col">
                              <p className="publish">Published</p>
                            </div>
                            <div className="col">
                              <p
                                className="detoal"
                                onClick={() => this.openModalSucess(items, i)}
                              >
                                <i class="fas fa-trash"></i>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="schoolModal">
                        <Modal
                          show={
                            this.state.openSucess &&
                            this.state.currentModalIndex == i
                          }
                        >
                          <Modal.Body className="Madl">
                            <div>
                              <p
                                className="closeModal"
                                onClick={this.closeSuucess}
                              >
                                <i className="fas fa-times"></i>
                              </p>
                            </div>
                            <div>
                              <p className="succpar"> Hey Admin! </p>
                              <p className="superball">
                                Are you sure you want to delete the job!
                              </p>
                              <div onSubmit={(e) => this.submitHandler(e)}>
                                <div className="d-flex">
                                  <div className="d-flex">
                                    <label className="lebelenter">
                                      Title of the Job:
                                    </label>
                                    <input
                                      className="formModalalt"
                                      name="res1"
                                      type="text"
                                      onChange={(e) => this.handleChange(e)}
                                      value={this.state.fields.res1}
                                    />
                                  </div>

                                  <br />
                                  <div className="d-flex">
                                    <label className="lebelenter">
                                      Business Name:
                                    </label>
                                    <input
                                      className="formModalalt"
                                      name="res2"
                                      type="text"
                                      onChange={(e) => this.handleChange(e)}
                                      value={this.state.fields.res2}
                                    />
                                  </div>
                                </div>

                                <br />

                                <button
                                  type="submit"
                                  className="btnsign"
                                  onClick={() => this.submitHandler()}
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          </Modal.Body>
                        </Modal>
                      </div>
                    </>
                  );
                })}
            </div>
          </Container>
        </div>
      </div>
    );
  }
}

export default Publish;
