import "./App.css";
import React, { Component } from "react";
import Main from "./components/Main";
import Login from "./components/Login";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import Auth from "./Auth";
function App() {
  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        Auth.getAuth() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
            }}
          />
        )
      }
    />
  );

  return (
    <div className="page-wrapper">
      <Router>
        <Route exact path="/" component={Login} />
        <PrivateRoute path="/Main" component={Main} />
        {/* <Route path="/Main" component={Main} /> */}
      </Router>
    </div>
  );
}

export default App;
