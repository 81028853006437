import React, { Component } from "react";
import Navbar from "./Navbar";
import { Row, Col, Container, Modal } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import SignOut from "./SignOut";
import axios from "axios";

export class Addjob extends Component {
  constructor(props) {
    super(props);
    this.state = {
      job: [],
      currentUser: {},
      openSucess: false,
      loading: false,
    };
  }
  closeSuucess = () => {
    this.setState({
      openSucess: false,
    });
  };
  openModalSucess = () => {
    this.setState({
      openSucess: true,
    });
  };

  handleClickCancel = () => {
    window.location.href = window.location.origin + "/Main/Publish";
  };
  handleChange = (e) => {
    let job = this.state.job;
    const value = e.target.type === "file" ? e.target.files[0] : e.target.value;
    job[e.target.name] = value;

    this.setState({
      job,
    });
  };

  handleSubmit = (e) => {
    // var token = localStorage.getItem("token");
    e.preventDefault();
    console.log(this.state.job);
    const formdata = new FormData();
    //console.log(Object.keys(this.state.product));
    this.setState({
      loading: true,
    });
    setTimeout(() => {
      this.setState({
        loading: false,
        openSucess: true,
      });
    }, 2000);

    Object.keys(this.state.job).forEach((key) => {
      formdata.append(key, this.state.job[key]);
    });

    console.log(formdata);

    axios
      .post("https://api.obsessedwithcapital.com/api/jobs/", formdata)
      .then((res) => {
        console.log(res);
        this.onClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { loading } = this.state;
    console.log(this.state.job);
    return (
      <div className="contentdashbord">
        <Navbar />
        <div className="welcome d-flex">
          <h6 className="activejobs">Job Application</h6>
        </div>

        <div className="newsectionadd">
          <div className=" allphoto">
            <form onSubmit={this.handleSubmit} method="post">
              <div class="row ml-3 ">
                <div class="col-6">
                  <label className="lebelenter">Company Name</label>
                  <input
                    className="formModalbus"
                    name="BusinessName"
                    type="text"
                    id="validationDefault01"
                    required
                    onChange={this.handleChange}
                    value={this.state.job.BusinessName}
                  />
                </div>
                <div className="col-6">
                  <label className="lebelenter">Job Title</label>
                  <input
                    className="formModalbus"
                    name="title"
                    type="text"
                    id="validationDefault01"
                    required
                    onChange={this.handleChange}
                    value={this.state.job.title}
                  />
                </div>
              </div>
              <div class="row ml-3 mt-3 nextphoto ">
                <div class="col-6 d-flex">
                  <div className="btnaddjob d-flex">
                    <i class="fas fa-plus"></i>
                    <input
                      className="chofile"
                      type="file"
                      name="jobImage"
                      onChange={this.handleChange}
                      aria-label="file example"
                      required
                    />
                    {/* <i class="fas fa-plus"></i> */}
                  </div>
                  <label className="lebelenter ml-3 mt-2">
                    Add Company’s Logo
                  </label>
                </div>

                <div className="col-6">
                  <label className="lebelenter">Tags</label>
                  <input
                    className="formModalbus"
                    name="tags"
                    type="text"
                    id="validationDefault01"
                    required
                    onChange={this.handleChange}
                    value={this.state.job.tags}
                  />
                </div>
              </div>
              <div class="row ml-3 mt-3 nextphoto">
                <div class="col-6">
                  <label className="lebelenter">Expected Salary</label>
                  <input
                    className="formModalbus"
                    name="salary"
                    type="text"
                    id="validationDefault01"
                    required
                    onChange={this.handleChange}
                    value={this.state.job.salary}
                  />
                </div>

                <div className="col-6">
                  <label className="lebelenter">Number Of Staff</label>
                  <input
                    className="formModalbus"
                    name="numberofstaff"
                    type="text"
                    id="validationDefault01"
                    required
                    onChange={this.handleChange}
                    value={this.state.job.numberofstaff}
                  />
                </div>
              </div>

              <div class="row ml-3 mt-5  d-block">
                <label className="lebelenter"> Job description</label>
                <input
                  className="formModalase"
                  name="description"
                  type="text"
                  id="validationDefault01"
                  required
                  onChange={this.handleChange}
                  value={this.state.job.description}
                />
              </div>

              <div className="btnhome ">
                <button
                  className="btnbook"
                  onClick={() => this.handleClickCancel()}
                >
                  Cancel
                </button>

                <input
                  className="btnappl mb-3"
                  type="submit"
                  value="Publish"
                  onClick={() => this.openModalSucess()}
                />
              </div>
            </form>
            {loading ? (
              <div className="schoolModal">
                <Modal show={this.state.openSucess}>
                  <Modal.Body className="Madl">
                    <div>
                      <p className="closeModal" onClick={this.closeSuucess}>
                        <i className="fas fa-times"></i>
                      </p>
                    </div>
                    <div>
                      <p className="succpar"> Successfully Sent! </p>
                      <p className="superball">
                        You did a great job it will directly be on job lists.
                      </p>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            ) : (
              () => this.openModalSucess()
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Addjob;
