import React, { Component } from "react";

const Auth = {
  isAuthenticated: false,

  authenticate() {
    this.isAuthenticated = true;
    let token = localStorage.getItem("token");
  },
  SignOut() {
    this.isAuthenticated = false;
    localStorage.removeItem("token");
    localStorage.clear();
  },
  getAuth() {
    if (localStorage.getItem("token")) {
      return true;
    } else {
      return false;
    }
  },
};
export default Auth;
