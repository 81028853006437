import React, { Component } from "react";
import { Container, Card, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import Auth from "../Auth";
import axios from "axios";
class Login extends Component {
  constructor() {
    super();
    this.state = {
      input: {},
      errors: {},
      blocker: [],
      loading: false,
    };

    if (Auth.getAuth()) {
      this.goToDashboard();
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.login = this.login.bind(this);
  }

  goToDashboard() {
    window.location.replace("/Main/Overall");
  }
  login() {
    Auth.authenticate();
    var data = new FormData();

    var config = {
      method: "post",
      url: "https://api.obsessedwithcapital.com/api/login/",
      headers: {},
      data: {
        email: this.state.input["email"],
        password: this.state.input["password"],
      },
    };

    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        if (response.data.token) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("data", JSON.stringify(response.data));
          this.goToDashboard();
        } else {
          console.log("no defined token genreated");
        }
        this.setState({
          loading: false,
        });
        const blocker = response.data;
        this.setState({ blocker });
      })
      .catch((error) => {
        console.log(error.response);
        console.log("");
        this.setState({
          loading: false,
        });
      });
  }

  handleChange(e) {
    let input = this.state.input;
    input[e.target.name] = e.target.value;
    this.setState({
      input,
    });
  }
  handleSubmit(e) {
    e.preventDefault();
    let input = {};
    input["email"] = "";
    input["password"] = "";
    this.setState({ input: input });

    let validLogin = this.validate();
    this.login();

    if (validLogin) {
      //this.login();
      // this.goToDashboard();
    } else {
      console.log("Not validated");
    }
  }
  validate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;

    if (typeof input["email"] !== undefined) {
      var pattern = new RegExp(/^\S+@\S+\.\S+$/);

      if (!pattern.test(input["email"])) {
        isValid = false;
        errors["email"] = "Incorrect Email";
      }
    }
    if (typeof input["password"] !== undefined) {
      var patternPass = new RegExp("^[a-zA-Z0-9_]*$");

      if (!patternPass.test(input["password"])) {
        isValid = false;
        errors["password"] = "Incorrect Password.";
      }
    }

    this.setState({
      errors: errors,
    });
    return isValid;
  }

  render() {
    const { loading } = this.state;
    return (
      <div>
        <header
          id="header"
          className="fixed-top d-flex align-items-center  header-transparent "
        >
          <Navbar expand="lg" className="Nav__black">
            <h1 className="logo ml-4">
              <img
                src="/images/logofinal.jpeg"
                className="logo-"
                alt="sidelogo"
              />
            </h1>
          </Navbar>
        </header>
        <div className="login">
          <div className="caerdlog">
            <h1 className="tslog">Log In</h1>
            <form
              onSubmit={this.handleSubmit}
              className="formdata"
              method="POST"
            >
              <div className="form-group forminput mt-3">
                <label className="lebelenterlog">Enter your username </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  id="validationDefault01"
                  required
                  value={this.state.input.email}
                  onChange={this.handleChange}
                  className="formModaltes"
                  placeholder="Enter email"
                />
              </div>
              {/* <div className="dangerdemor">{this.state.errors.email}</div> */}

              <div className="form-group forminput mt-4">
                <label className="lebelenterlog"> Enter your Password </label>
                <input
                  type="password"
                  name="password"
                  id="validationDefault01"
                  required
                  value={this.state.input.password}
                  onChange={this.handleChange}
                  className="formModaltes"
                  placeholder="Enter password"
                />
              </div>
              {/* <div className="dangerdemor">{this.state.errors.password}</div> */}

              <button type="submit" value="Login" className="btnapplsign mt-4">
                {!loading && <span className="  mt-4">Login</span>}
                {loading && <span className="  mt-4">Loading ...</span>}
              </button>
              <br />
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
