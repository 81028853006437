import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import Siderbar from "./Siderbar";
import Overall from "./Overall";
import Publish from "./publish";
import Companies from "./companies";
import Aplications from "./applicants";
import Interview from "./interview";
import Blog from "./blog";
import Faq from "./faq";
import Addjob from "./addjob";
import Jobapplications from "./jobapplications";
import InactiveApplicants from "./inactiveapplicant";

export class Main extends Component {
  render() {
    return (
      <div className="page-content">
        <Siderbar />

        <Switch>
          <Route path="/Main/Overall" component={Overall} />
          <Route path="/Main/Publish" component={Publish} />
          <Route path="/Main/Companies" component={Companies} />
          <Route path="/Main/Aplications" component={Aplications} />
          <Route path="/Main/Interview" component={Interview} />
          <Route path="/Main/Blog" component={Blog} />
          <Route path="/Main/Faq" component={Faq} />
          <Route path="/Main/Addjob" component={Addjob} />
          <Route path="/Main/Jobapplications" component={Jobapplications} />
          <Route
            path="/Main/InactiveApplicants"
            component={InactiveApplicants}
          />
        </Switch>
      </div>
    );
  }
}

export default Main;
