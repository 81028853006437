import React, { Component } from "react";
import { Row, Col, Container, Modal } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import Auth from "../Auth";
import axios from "axios";
import Navbar from "./Navbar";
import SignOut from "./SignOut";
export class Companies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companies: [],
      openSucess: false,
      currentModalIndex: null,
      message: {
        name: "",
        mess: "",
      },
    };
  }

  componentDidMount = () => {
    this.getComp();
  };
  closeSuucess = () => {
    this.setState({
      openSucess: false,
    });
  };
  openModalSucess = (items, i) => {
    this.setState({
      openSucess: true,
      currentModalIndex: i,
      message: {
        mess: items.messages,
        name: items.BusinessName,
      },
    });
  };

  getComp = () => {
    var token = localStorage.getItem("token");
    var config = {
      method: "get",
      url: "https://api.obsessedwithcapital.com/api/companys/",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        this.setState({
          companies: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  render() {
    const { companies } = this.state;
    return (
      <div>
        <div className="contentdashbord">
          <Navbar />
          <Container fluid>
            <Row>
              <Col md-10>
                <div className="welcome d-flex">
                  <h6 className="activejobs">Company Requests</h6>
                </div>
              </Col>

              <Col md-2 className="d-flex justify-content-space-between">
                <div className="search mb-3">
                  <div className="header-items d-flex align-items-center pt-5">
                    <div className="">
                      <input
                        id="search"
                        type="text"
                        className="loginsearchfa"
                        placeholder="Search here ..."
                      />
                    </div>
                  </div>
                </div>
                <SignOut />
              </Col>
            </Row>
            <br />
            <div className=" cardtable">
              <div class="table-responsive">
                <table class="table table-hover mb-0">
                  <thead>
                    <tr>
                      <th>Requests Details</th>
                      <th>Business Type</th>
                      <th>Email</th>
                      <th>Phone number</th>
                      <th>Date</th>
                      <th>Message</th>
                    </tr>
                  </thead>
                  <tbody>
                    {companies &&
                      companies.map((items, i) => {
                        return (
                          <>
                            <tr>
                              <td className="userpron">{items.BusinessName}</td>
                              <td className="userpron ">
                                {items.BusinessType}
                              </td>
                              <td className="userpron ">{items.email}</td>
                              <td className="userpron ">{items.phonenumber}</td>
                              <td className="userpron ">{items.dateOut}</td>
                              <td>
                                <p
                                  className="viewdetclionme "
                                  onClick={() => this.openModalSucess(items, i)}
                                >
                                  View Message
                                </p>
                              </td>
                            </tr>
                            <div className="schoolModal">
                              <Modal
                                show={
                                  this.state.openSucess &&
                                  this.state.currentModalIndex == i
                                }
                                // onHide={this.close}
                              >
                                <Modal.Body className="Madl">
                                  <div>
                                    <p
                                      className="closeModal"
                                      onClick={this.closeSuucess}
                                    >
                                      <i className="fas fa-times"></i>
                                    </p>
                                  </div>
                                  <div>
                                    <p className="succpar">
                                      From {items.BusinessName}{" "}
                                    </p>
                                    <p className="superball">
                                      {items.message}{" "}
                                    </p>
                                  </div>
                                </Modal.Body>
                              </Modal>
                            </div>
                          </>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}

export default Companies;
