import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Container, Button, Modal } from "react-bootstrap";
import Chartover from "./Chartover";
import Navbar from "./Navbar";
import SignOut from "./SignOut";
import axios from "axios";
class Overall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeApp: [],
      inactiveApp: [],
      dataOut: 5,
    };
  }
  gotToMOre = () => {
    window.location.href = window.location.origin + "/Main/Jobapplications";
  };
  handleInter = () => {
    window.location.href = window.location.origin + "/Main/Interview";
  };
  handleComp = () => {
    window.location.href = window.location.origin + "/Main/Companies";
  };
  handlepp = () => {
    window.location.href = window.location.origin + "/Main/Aplications";
  };

  componentDidMount = () => {
    this.getJobs();
    this.getComp();
    this.getUsers();
    this.getApplication();
    this.getApproved();
    this.getDined();
  };

  getJobs = () => {
    var config = {
      method: "get",
      url: "https://api.obsessedwithcapital.com/api/jobs",
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((response) => {
        this.setState({
          jobs: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getApproved = () => {
    var token = localStorage.getItem("token");
    var config = {
      method: "get",
      url: `https://api.obsessedwithcapital.com/api/applications/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        this.setState({
          approved: response.data
            .filter((item) => item.approved)
            .sort((a, b) => (new Date(a) < new Date(b) ? 1 : -1)),
          dataOut: 5,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getDined = () => {
    var token = localStorage.getItem("token");
    var config = {
      method: "get",
      url: `https://api.obsessedwithcapital.com/api/applications/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        this.setState({
          deinials: response.data
            .filter((item) => !item.approved)
            .sort((a, b) => (new Date(a) < new Date(b) ? 1 : -1)),
          dataOut: 5,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getApplication = () => {
    var token = localStorage.getItem("token");
    var config = {
      method: "get",
      url: "https://api.obsessedwithcapital.com/api/applications",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        console.log(response);
        this.setState({
          application: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getComp = () => {
    var token = localStorage.getItem("token");
    var config = {
      method: "get",
      url: "https://api.obsessedwithcapital.com/api/interview/",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        this.setState({
          interview: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getUsers = () => {
    var token = localStorage.getItem("token");
    var config = {
      method: "get",
      url: "https://api.obsessedwithcapital.com/api/users/",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        this.setState({
          applicants: response.data,
          activeApp: response.data.filter((item) => item.active),
          inactiveApp: response.data.filter((item) => !item.active),
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  render() {
    const {
      jobs,
      interview,
      applicants,
      application,
      inactiveApp,
      activeApp,
      deinials,
      approved,
    } = this.state;
    console.log(jobs);
    return (
      <div>
        <div className="contentdashbord">
          <Navbar />
          <SignOut />
          <Container fluid>
            <div className="welcome d-flex">
              <h6 clasName="Admin">Hi Admin, welcome Back</h6>
            </div>

            <br />

            <div>
              <Row>
                <Col>
                  <Card className="carddetails">
                    <Card.Body>
                      <div className="d-block">
                        <p className="descr"> Applicants</p>
                        <p className="number">
                          {applicants && applicants.length}
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card className="carddetails">
                    <Card.Body>
                      <div className="d-block">
                        <p className="descr"> Active Applicants</p>
                        <p className="number">
                          {activeApp && activeApp.length}
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card className="carddetails">
                    <Card.Body>
                      <div className="d-block">
                        <p className="descr"> Jobs</p>
                        <p className="number">{jobs && jobs.length}</p>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card className="carddetails mb-3">
                    <Card.Body>
                      <div className="d-block">
                        <p className="descr"> Job Applications</p>
                        <p className="number">
                          {application && application.length}
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <Row className="cardrow">
                <Col xs="12" md="6" lg="6">
                  <Card className=" mb-3">
                    <Card.Body>
                      <Row className="rowschoolltitle">
                        <h6 className="infoheader">
                          {" "}
                          {deinials && deinials.length} Pending Job Applications
                        </h6>
                      </Row>
                      {deinials &&
                        deinials.slice(0, this.state.dataOut).map((buse) => {
                          return (
                            <Row className="rowschooll">
                              <Col>
                                <div className="d-flex">
                                  <i class="fas fa-bus mt-3 mr-2"></i>
                                  <p className="busesoverl  mt-3 mr-2">
                                    {buse.BusinessName}
                                  </p>
                                </div>
                              </Col>
                              <Col>
                                <div className="tranctionheader">
                                  <p className="busesoverl  mt-3 mr-2">
                                    {buse.FirstName} {buse.LastName}
                                  </p>
                                </div>
                              </Col>
                            </Row>
                          );
                        })}
                      <button
                        className="viewdetclionme  "
                        onClick={() => this.gotToMOre()}
                      >
                        View More
                      </button>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs="12" md="6" lg="6">
                  <Card className=" mb-3">
                    <Card.Body>
                      <Row className="rowschoolltitle">
                        <h6 className="infoheader">
                          {" "}
                          {approved && approved.length} Appoved Job Applications
                        </h6>
                      </Row>
                      {approved &&
                        approved.slice(0, this.state.dataOut).map((buse) => {
                          return (
                            <Row className="rowschooll">
                              <Col>
                                <div className="d-flex">
                                  <p className="busesoverl  mt-3 mr-2">
                                    {buse.BusinessName}
                                  </p>
                                </div>
                              </Col>
                              <Col>
                                <div className="tranctionheader">
                                  <p className="busesoverl  mt-3 mr-2">
                                    {buse.FirstName} {buse.LastName}
                                  </p>
                                </div>
                              </Col>
                            </Row>
                          );
                        })}
                      <button
                        className="viewdetclionme "
                        onClick={() => this.gotToMOre()}
                      >
                        View More
                      </button>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <br />
            </div>
          </Container>
        </div>
      </div>
    );
  }
}

export default Overall;
