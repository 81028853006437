import React, { Component } from "react";
import Auth from "../Auth";
import { Link, withRouter } from "react-router-dom";
import { Button, Row, Col } from "react-bootstrap";
class Siderbar extends Component {
  redirect = () => {
    const { history } = this.props;
    if (history) history.push("/");
  };

  logout() {
    Auth.SignOut();
  }

  handleLogOut = (e) => {
    e.preventDefault();
    this.logout();
    this.redirect();
  };
  render() {
    return (
      <div>
        {" "}
        <nav className="sidebar-wrapper">
          <div
            className="slimScrollDiv"
            style={{
              position: "relative",
              overflowY: "hidden",
              width: "auto",
              height: " 100%",
            }}
          >
            <div className="sidebar-brand d-flex" id="sidebar">
              <Link to="/Main/Overall">
                <img
                  src="/images/logofinal.jpeg"
                  className="logo-"
                  alt="sidelogo"
                />
              </Link>
              <span className="logdashboard">OWC</span>
            </div>
            <div className="Nav_right">
              <ul className="ullinks ">
                <li className="Nav__item">
                  <span>
                    <i class="fas fa-columns"></i>
                  </span>

                  <Link className="Nav__link " to="/Main/Overall">
                    Overview
                  </Link>
                </li>
                <li className="Nav__item">
                  <span>
                    <i class="fas fa-upload"></i>
                  </span>
                  <Link className="Nav__link " to="/Main/Publish">
                    Publish Jobs
                  </Link>
                </li>

                <li className="Nav__item">
                  <span>
                    <i class="fas fa-user-alt"></i>
                  </span>
                  <Link className="Nav__link " to="/Main/Aplications">
                    Activated Applicants
                  </Link>
                </li>
                <li className="Nav__item">
                  <span>
                    <i class="fas fa-user-alt"></i>
                  </span>
                  <Link className="Nav__link " to="/Main/InactiveApplicants">
                    Inactive Applicants
                  </Link>
                </li>
                <li className="Nav__item">
                  <span>
                    <i class="fas fa-user-edit"></i>
                  </span>
                  <Link className="Nav__link " to="/Main/Jobapplications">
                    Job Applications
                  </Link>
                </li>
                <li className="Nav__item">
                  <span>
                    <i class="fas fa-building"></i>
                  </span>
                  <Link className="Nav__link " to="/Main/Companies">
                    Companies
                  </Link>
                </li>
                <li className="Nav__item">
                  <span>
                    <i class="fas fa-voicemail"></i>
                  </span>
                  <Link className="Nav__link " to="/Main/Interview">
                    Interviews
                  </Link>
                </li>
                <li className="">
                  <Button className="logoutside" onClick={this.handleLogOut}>
                    <span>
                      <i class="far fa-arrow-alt-circle-right mr-3"></i>
                    </span>
                    Sign out
                  </Button>
                </li>
                {/* <li className="Nav__item">
                  <span>
                    <i class="fas fa-book"></i>
                  </span>
                  <Link className="Nav__link " to="/Main/Faq">
                    Faqs
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default Siderbar;
