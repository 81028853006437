import React, { Component } from "react";
import Navbar from "./Navbar";
import { Row, Col, Container } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import SignOut from "./SignOut";
export class Faq extends Component {
  render() {
    return (
      <div className="contentdashbord">
        <Navbar />
        <div>
          <Row>
            <Col md-10>
              <div className="welcome d-flex">
                <h6 className="activejobs">Blog Posts Control</h6>
              </div>
            </Col>

            <Col md-2 className="d-flex justify-content-space-between">
              <div className="search mb-3">
                <div className="header-items d-flex align-items-center pt-5">
                  <div className="searchoverall mr-auto in">
                    <input
                      id="search"
                      type="text"
                      className="searchsquery mt-0"
                      placeholder="Search here ..."
                    />
                    <i
                      className="fas fa-search"
                      // onClick={this.searchHandle}
                    ></i>
                  </div>
                </div>
              </div>
              <SignOut />
            </Col>
          </Row>
        </div>
        <div className="askedq">
          <div className="row">
            <div className="col-3"></div>
            <div className="col-6">
              <h4 className="asktit">Asked Questions</h4>

              <div className="bodyasky">
                <p className="ques">Why there are no full-time jobs?</p>
                <p className="ansewq">Answer</p>
              </div>
              <div className="bodyasky">
                <p className="ques">Why there are no full-time jobs?</p>
                <p className="ansewq">Answer</p>
              </div>
              <div className="bodyasky">
                <p className="ques">Why there are no full-time jobs?</p>
                <p className="ansewq">Answer</p>
              </div>
            </div>
            <div className="col-3"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Faq;
