import React, { Component } from "react";
import Navbar from "./Navbar";
import { Row, Col, Container } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import SignOut from "./SignOut";
export class Blog extends Component {
  render() {
    return (
      <div className="contentdashbord">
        <Navbar />
        <div>
          <Row>
            <Col md-10>
              <div className="welcome d-flex">
                <h6 className="activejobs">Blog Posts Control</h6>
              </div>
            </Col>

            <Col md-2 className="d-flex justify-content-space-between">
              <div className="search mb-3">
                <div className="header-items d-flex align-items-center pt-5">
                  <div className="searchoverall mr-auto in">
                    <input
                      id="search"
                      type="text"
                      className="searchsquery mt-0"
                      placeholder="Search here ..."
                    />
                    <i
                      className="fas fa-search"
                      // onClick={this.searchHandle}
                    ></i>
                  </div>
                </div>
              </div>
              <SignOut />
            </Col>
          </Row>
        </div>
        <div className="newsection">
          {/* <div className="containblog ">
            <h3 className="titleblog">All News In One Place. </h3>
            <h3 className="titleblogt">Millions of opportunities.</h3>
          </div> */}
          <div className="btnaddblog">
            <i class="fas fa-plus"></i>
          </div>
          <div className=" allphoto">
            <div class="row ml-3">
              <div class="col-6">
                <img src="/images/hand.png" className="news image-fluid" />
                <div className="card cardphoto">
                  <div className="card-body">
                    <h4 className="name">News</h4>
                    <p className="titleceo">
                      Our Staff finished just wrapped up the work
                    </p>
                    <p>Read more about our celebation.</p>
                  </div>
                </div>
                <div className="d-flex">
                  <p className="viewedit">Edit</p>
                  <p className="viewedel">Delete</p>
                  <p className="viewdpubl">Publish</p>
                </div>
              </div>
              <div className="col-6">
                <div className="ml-3">
                  <img
                    src="/images/homeblo.png"
                    className="newside  image-fluid"
                  />
                  <h4 className="name">News</h4>
                  <p className="titleceo">
                    Our Staff finished just wrapped up the work
                  </p>
                  <p className="titlece">they have been doing for 5 months.</p>
                  <div className="d-flex">
                    <p className="viewedit">Edit</p>
                    <p className="viewedel">Delete</p>
                    <p className="viewdpubl">Publish</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row ml-3 mt-5 nextphoto">
              <div class="col-6">
                <img src="/images/xiji.png" className="news image-fluid" />
                <div className="d-flex">
                  <p className="viewedit">Edit</p>
                  <p className="viewedel">Delete</p>
                  <p className="viewdpubl">Publish</p>
                </div>
              </div>

              <div className="col-6">
                <div className="ml-3">
                  <h4 className="name">News</h4>
                  <h3>Our Master Chef Class</h3>
                  <h3>Wanna Join?</h3>
                  <p className="titleceo">
                    Our Staff finished just wrapped up the work
                  </p>
                  <p className="titlece">they have been doing for 5 months.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Blog;
