import { getCompanyById } from "../actions";

const initialState = { company: [], companies: [], companyById: {} };
export default (state = initialState, action) => {
  switch (action.type) {
    case "SAVE_COMPANY":
      return {
        ...state,
        company: [action.payload, ...state.company],
      };
    case "GET COMPANIES SUCCESS":
      return {
        ...state,
        companies: action.payload,
      };
    case "GET COMPANY BY ID":
      return {
        ...state,
        companyById: action.payload,
      };
    default:
      return state;
  }
};
