import React, { Component } from "react";

export class Navbar extends Component {
  toggleSidebar = () => {
    document.body.classList.toggle("fixed-left-void");
    document.querySelector(".page-wrapper").classList.toggle("enlarged");
  };
  render() {
    return (
      <div>
        <div className="toggle" onClick={this.toggleSidebar}>
          <button type="button" className="tooglebutton ">
            <i class="fas fa-bars"></i>
          </button>
        </div>
      </div>
    );
  }
}

export default Navbar;
