const initialState = { school: [], schools: [] };
export default (state = initialState, action) => {
  switch (action.type) {
    case "SAVE_SCHOOL":
      return {
        ...state,
        school: [action.payload, ...state.school],
      };
    case "GET SCHOOLS SUCCESS":
      return {
        ...state,
        schools: action.payload,
      };
    default:
      return state;
  }
};
