import React, { Component } from "react";
import { Row, Col, Container, Modal } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import Auth from "../Auth";
import Navbar from "./Navbar";
import SignOut from "./SignOut";
import axios from "axios";
export class Interview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      interview: [],
      application: [],
      stuInfoma: [],
      openSucess: false,
      fields: { res1: "", res2: "" },
      currentModalIndex: null,
      selectedInt: [],
      searchItem: "",
      dataOut: 10,
    };
  }
  closeSuucess = () => {
    this.setState({
      openSucess: false,
    });
  };
  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
  }
  componentDidMount = () => {
    this.getInterview();
  };
  openModalSucess = (intsel, i) => {
    this.setState({
      selectedInt: intsel,
      openSucess: true,
      fields: { res1: intsel.FirstName, res2: intsel.LastName },
      currentModalIndex: i,
    });
  };
  getInterview() {
    var token = localStorage.getItem("token");
    var config = {
      method: "get",
      url: "https://api.obsessedwithcapital.com/api/interview",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        console.log(response.data);
        this.setState({
          interview: response.data,
          dataOut: 10,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  submitHandler = () => {
    let interSelId = this.state.selectedInt._id;
    var token = localStorage.getItem("token");
    var config = {
      method: "DELETE",
      url:
        "https://api.obsessedwithcapital.com/api/interview/delete/" +
        interSelId,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        alert("you have successfully deleted the interview");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  removeInt = (id, items) => {
    return (
      <p className="viewdetclion" onClick={() => this.handleOpen(id, items)}>
        Delete
      </p>
    );
  };
  handleUserChange = (e) => {
    this.setState({
      searchItem: e.target.value,
    });
  };
  loadmore = () => {
    this.setState((newdata) => {
      return {
        dataOut: newdata.dataOut + 10,
      };
    });
  };

  render() {
    const { interview } = this.state;

    return (
      <div>
        <div className="contentdashbord">
          <Navbar />
          <SignOut />
          <br />

          <Container fluid>
            <h6 className="activejobs">Interviews Scheduled</h6>
            <div className="searchexp">
              <input
                type="text"
                id="search"
                className="loginsearchfa"
                placeholder=" Search Users"
                onChange={(e) => this.handleUserChange(e)}
              />
            </div>

            <div className=" cardtable">
              <p className=" contentmemb">
                All Interviews: {interview && interview.length}
              </p>
              <div class="table-responsive">
                <table class="table table-hover  table-responsive">
                  <thead>
                    <tr>
                      <th className="userprony ">Applicant Details</th>
                      <th className="userprony ">Email</th>
                      <th className="userprony ">phonenumber</th>
                      <th className="userprony ">Role </th>
                      <th className="userprony ">Date for application</th>
                      <th className="userprony ">Date for interview</th>
                      <th className="userprony ">Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {interview &&
                      interview
                        .filter((item) =>
                          item.FirstName?.toLowerCase().includes(
                            this.state.searchItem
                          )
                        )
                        .slice(0, this.state.dataOut)
                        .map((items, i) => {
                          return (
                            <tr>
                              <td className="userpron ">
                                {items.LastName} {items.FirstName}
                              </td>
                              <td className="userpron ">{items.email}</td>
                              <td className="userpron  ">
                                {items.phonenumber}
                              </td>
                              <td className="userpron ">{items.title}</td>
                              <td className="userpron ">
                                {items.date.toString().substring(0, 10)}
                              </td>
                              <td className="userpron ">
                                {items.InterviewDay}
                              </td>
                              <td className="userpron ">
                                {items.InterviewTime}
                              </td>
                              <td>
                                <button
                                  className="viewdetclion"
                                  onClick={() => this.openModalSucess(items, i)}
                                >
                                  Delete
                                </button>
                              </td>
                              <div className="schoolModal">
                                <Modal
                                  show={
                                    this.state.openSucess &&
                                    this.state.currentModalIndex == i
                                  }
                                >
                                  <Modal.Body className="Madl">
                                    <div>
                                      <p
                                        className="closeModal"
                                        onClick={this.closeSuucess}
                                      >
                                        <i className="fas fa-times"></i>
                                      </p>
                                    </div>
                                    <div>
                                      <p className="succpar"> Hey Admin! </p>
                                      <p className="superball">
                                        Are you sure you want to delete the job!
                                      </p>
                                      <div>
                                        <div className="d-flex">
                                          <div className="d-flex">
                                            <label className="lebelenter">
                                              Title of the Job:
                                            </label>
                                            <input
                                              className="formModalalt"
                                              name="res1"
                                              type="text"
                                              onChange={(e) =>
                                                this.handleChange(e)
                                              }
                                              value={this.state.fields.res1}
                                            />
                                          </div>

                                          <br />
                                          <div className="d-flex">
                                            <label className="lebelenter">
                                              Business Name:
                                            </label>
                                            <input
                                              className="formModalalt"
                                              name="res2"
                                              type="text"
                                              onChange={(e) =>
                                                this.handleChange(e)
                                              }
                                              value={this.state.fields.res2}
                                            />
                                          </div>
                                        </div>

                                        <br />

                                        <button
                                          type="submit"
                                          className="viewdetclion"
                                          onClick={() => this.submitHandler()}
                                        >
                                          Delete
                                        </button>
                                      </div>
                                    </div>
                                  </Modal.Body>
                                </Modal>
                              </div>
                            </tr>
                          );
                        })}
                    <br />
                    {interview && interview.length <= this.state.dataOut ? (
                      ""
                    ) : (
                      <div className="buttoncenter mt-5">
                        {interview && interview.length >= this.state.dataOut ? (
                          <button
                            type="button"
                            className="btnbookrermore mt-5 "
                            onClick={() => this.loadmore()}
                          >
                            <span className="mr-2 mt-1">
                              <i class="fas fa-chevron-down"></i>
                            </span>
                            <span>Load More</span>
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}

export default Interview;
