import React, { Component } from "react";
import { Row, Col, Container, Card, Modal } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import Auth from "../Auth";
import axios from "axios";
import Navbar from "./Navbar";
import SignOut from "./SignOut";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
export class Jobapplications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      applicants: [],
      applicantdetails: [],
      openUpdate: false,
      openSucess: false,
      fields: { res1: "", res2: "" },
      currentModalIndex: null,
      selectedInt: [],
      searchItem: "",
      dataOut: 10,
      filters: {
        datefrom: "",
        dateTo: "",
      },
      status: "",
      startDate: undefined,
      endDate: undefined,
      bgcolor: "",
    };
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
  }
  handleChangeDate(date) {
    this.setState({
      startDate: new Date(date),
    });

    let filters = this.state.filters;
    if (filters == "") {
      filters.datefrom = format(Date.parse(date), "yyyy-MM-dd HH:mm:ss");
      this.setState({
        filters: filters,
      });
    } else
      this.setState({
        filters: this.state.startDate,
      });

    console.log(this.state.filters);
  }
  handleChangeEndDate(date) {
    this.setState({
      endDate: new Date(date),
    });

    let filters = this.state.filters;
    if (filters == "") {
      filters.dateTo = format(Date.parse(date), "yyyy-MM-dd HH:mm:ss");
      this.setState({
        filters: filters,
      });
    } else
      this.setState({
        filters: this.state.endDate,
      });

    console.log(this.state.filters);
  }
  componentDidMount = () => {
    this.getJobs();
  };
  openModalUpdated = (intsel, i) => {
    this.setState({
      selectedInt: intsel,
      openUpdate: true,
      fields: { res1: intsel.FirstName, res2: intsel.LastName },
      currentModalIndex: i,
    });
  };
  openModalSucess = (intsel, i) => {
    this.setState({
      selectedInt: intsel,
      openSucess: true,
      fields: { res1: intsel.FirstName, res2: intsel.LastName },
      currentModalIndex: i,
    });
  };
  handleDupChange = (e) => {
    let datfield = this.state.datfield;
    datfield[e.target.name] = e.target.value;
    this.setState({ datfield });
  };
  closeSuucess = () => {
    this.setState({
      openSucess: false,
    });
  };
  closeUpdate = () => {
    this.setState({
      openUpdate: false,
    });
  };
  getJobs = () => {
    var token = localStorage.getItem("token");
    var config = {
      method: "get",
      url: "https://api.obsessedwithcapital.com/api/applications",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        this.setState({
          applicants: response.data.sort((a, b) =>
            new Date(a) < new Date(b) ? 1 : -1
          ),
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleUserChange = (e) => {
    this.setState({
      searchItem: e.target.value,
    });
  };
  handleChangeApproved = () => {
    var token = localStorage.getItem("token");
    var config = {
      method: "get",
      url: `https://api.obsessedwithcapital.com/api/applications/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        this.setState({
          applicants: response.data
            .filter((item) => item.approved)
            .sort((a, b) => (new Date(a) < new Date(b) ? 1 : -1)),
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleChangeDined = () => {
    var token = localStorage.getItem("token");
    var config = {
      method: "get",
      url: `https://api.obsessedwithcapital.com/api/applications/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        this.setState({
          applicants: response.data
            .filter((item) => !item.approved)
            .sort((a, b) => (new Date(a) < new Date(b) ? 1 : -1)),
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleClick = (id) => {
    let jobId = id;
    console.log(jobId);
    var token = localStorage.getItem("token");
    var config = {
      method: "get",
      url: `https://api.obsessedwithcapital.com/api/applications/${jobId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        let fakeArr = [];
        fakeArr.push(response.data);

        this.setState({
          applicantdetails: fakeArr,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  submitHandler = () => {
    let interSelId = this.state.selectedInt._id;
    console.log(interSelId);
    var token = localStorage.getItem("token");
    var config = {
      method: "put",
      url:
        "https://api.obsessedwithcapital.com/api/applications/deny/" +
        interSelId,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        window.location.href = window.location.origin + "/Main/Jobapplications";
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleChangeUpdate = (e) => {
    let passport = this.state.passport;
    const value = e.target.type === "file" ? e.target.files[0] : e.target.value;
    passport[e.target.name] = value;

    this.setState({
      passport,
    });
  };
  submitHandlerUpdate = (e) => {
    let interSelId = this.state.selectedInt._id;
    console.log(interSelId);
    var token = localStorage.getItem("token");
    var config = {
      method: "put",
      url:
        "https://api.obsessedwithcapital.com/api/applications/approve/" +
        interSelId,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        window.location.href = window.location.origin + "/Main/Jobapplications";
      })
      .catch((error) => {
        console.log(error);
      });
  };
  Remove = (id, items) => {
    return (
      <p className="viewdetclion " onClick={() => this.handleOpen(id, items)}>
        Delete
      </p>
    );
  };
  loadmore = () => {
    this.setState((newdata) => {
      return {
        dataOut: newdata.dataOut + 10,
      };
    });
  };

  render() {
    const { applicants, applicantdetails } = this.state;

    const dateFilter =
      this.state.startDate != undefined
        ? format(Date.parse(this.state.startDate), "yyyy-MM-dd")
        : "";
    const dateFilterEnd =
      this.state.endDate != undefined
        ? format(Date.parse(this.state.endDate), "yyyy-MM-dd")
        : "";
    const resultDate =
      applicants &&
      applicants.filter((ite) => {
        return (
          ((ite.date.substring(0, 10) >= dateFilter && dateFilter != "") ||
            dateFilter === "") &&
          ((ite.date.substring(0, 10) <= dateFilterEnd &&
            dateFilterEnd != "") ||
            dateFilterEnd === "")
        );
      });
    return (
      <div>
        <div className="contentdashbord">
          <Navbar />
          <Container fluid>
            <Row>
              <Col md-10></Col>

              <Col md-2 className="d-flex justify-content-end rowpush">
                <div className="search ">
                  <div className="header-items d-flex align-items-center pt-2">
                    <div className="d-flex mt-5">
                      <p className="d-flex ">
                        <div className="conteinpu  mt-2">Date From</div>
                        <div className=" parainputsapphf ">
                          <DatePicker
                            selected={this.state.startDate}
                            //onChange={(e) => this.handleChangeDate(e)}
                            onChange={this.handleChangeDate}
                            name="transactionDate"
                            className="loginsearchfa"
                            dateFormat="yyyy-MM-dd"
                            placeholder="Pick a date"
                            id="validationDefault01"
                            required
                          />
                        </div>
                      </p>
                      <p className="d-flex ">
                        <div className="conteinpu mt-2">Date To</div>
                        <div className=" parainputsapphf ">
                          <DatePicker
                            selected={this.state.endDate}
                            //onChange={(e) => this.handleChangeDate(e)}
                            onChange={this.handleChangeEndDate}
                            name="transactionDate"
                            className="loginsearchfa"
                            dateFormat="yyyy-MM-dd"
                            placeholder="Pick a date"
                            id="validationDefault01"
                            required
                          />
                        </div>
                      </p>
                      <input
                        id="search"
                        type="text"
                        className="loginsearchfa"
                        placeholder="Search here ..."
                        onChange={(e) => this.handleUserChange(e)}
                      />
                      {/* <i
                        className="fas fa-search"
                        // onClick={this.searchHandle}
                      ></i> */}
                    </div>
                  </div>
                </div>
                <SignOut />
              </Col>
            </Row>
            <br />
            <div className="rowup">
              <p className=" contentmemb">
                Job applicantions: {applicants && applicants.length}
              </p>

              <div className=" cardtable">
                <div className="">
                  <button
                    className="btnd"
                    onClick={() => this.handleChangeDined()}
                  >
                    Pending Applicants
                  </button>
                  <button
                    className="btnd"
                    style={{ backgroundColor: this.state.bgcolor }}
                    onClick={() => this.handleChangeApproved()}
                  >
                    Approved Applicants
                  </button>
                </div>
                <div className="row ">
                  <div className=" col-md-auto">
                    <div className="stucont">
                      <table className="table-container table-responsive">
                        <tr className="table-row-head">
                          <th>Name</th>
                          <th>Email</th>
                          <th>Phone Numbe</th>
                          <th>Date</th>
                          <th>Details</th>
                        </tr>
                        <tbody>
                          {dateFilter != undefined &&
                          dateFilterEnd != undefined ? (
                            <>
                              {resultDate &&
                                resultDate

                                  .filter((item) =>
                                    item.FirstName?.toLowerCase().includes(
                                      this.state.searchItem
                                    )
                                  )
                                  .slice(0, this.state.dataOut)

                                  .map((items, key) => {
                                    return (
                                      <tr className="table-row">
                                        <td>
                                          {items.LastName} {items.FirstName}
                                        </td>
                                        <td>
                                          {" "}
                                          {items.email && items.email.length ? (
                                            <p className="detailsbus">
                                              {items.email}
                                            </p>
                                          ) : (
                                            <p className="detailsbusnofond">
                                              {" "}
                                              No specific location{" "}
                                            </p>
                                          )}
                                        </td>
                                        <td> {items.phonenumber}</td>
                                        <td>
                                          {" "}
                                          {items.date
                                            .toString()
                                            .substring(0, 10)}
                                        </td>
                                        <td>
                                          <button
                                            className="detailsdest"
                                            onClick={() =>
                                              this.handleClick(items._id)
                                            }
                                          >
                                            view details
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  })}
                            </>
                          ) : (
                            <>
                              {applicants &&
                                applicants

                                  .filter((item) =>
                                    item.FirstName?.toLowerCase().includes(
                                      this.state.searchItem
                                    )
                                  )
                                  .slice(0, this.state.dataOut)

                                  .map((items, key) => {
                                    return (
                                      <tr className="table-row">
                                        <td>
                                          {items.LastName} {items.FirstName}
                                        </td>
                                        <td>
                                          {" "}
                                          {items.email && items.email.length ? (
                                            <p className="detailsbus">
                                              {items.email}
                                            </p>
                                          ) : (
                                            <p className="detailsbusnofond">
                                              {" "}
                                              No specific location{" "}
                                            </p>
                                          )}
                                        </td>
                                        <td> {items.phonenumber}</td>
                                        <td>
                                          {" "}
                                          {items.date
                                            .toString()
                                            .substring(0, 10)}
                                        </td>
                                        <td>
                                          <button
                                            className="detailsdest"
                                            onClick={() =>
                                              this.handleClick(items._id)
                                            }
                                          >
                                            view details
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  })}
                            </>
                          )}

                          {applicants &&
                          applicants.length <= this.state.dataOut ? (
                            ""
                          ) : (
                            <div className="buttoncenter">
                              {applicants &&
                              applicants.length >= this.state.dataOut ? (
                                <button
                                  type="button"
                                  className="btnbookrermore  "
                                  onClick={() => this.loadmore()}
                                >
                                  <span className="mr-2 mt-1">
                                    <i class="fas fa-chevron-down"></i>
                                  </span>
                                  <span>Load More</span>
                                </button>
                              ) : (
                                ""
                              )}
                            </div>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-4 ">
                    {applicantdetails &&
                      applicantdetails.map((items, key, i) => {
                        return (
                          <Card className="caerdui">
                            <div className="peacetitle">
                              <p className="peacepara">
                                Job Application Summary
                              </p>
                            </div>

                            <Card.Body>
                              <div className="imginfog">
                                <h3 className="summa"></h3>
                                <p className="imgparahe">
                                  Applicant Names
                                  <span className="imgparabody">
                                    {items.FirstName} {items.LastName}
                                  </span>
                                </p>
                                <p className="imgparahe">
                                  Status
                                  {applicantdetails[0] &&
                                  applicantdetails[0].approved == null ? (
                                    <span className="imgparabodyy">
                                      {" "}
                                      Pending
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {applicantdetails[0] &&
                                  applicantdetails[0].approved == true ? (
                                    <span className="imgparabodyi">
                                      {" "}
                                      Approved
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {applicantdetails[0] &&
                                  applicantdetails[0].approved == false ? (
                                    <span className="imgparabodyy">
                                      {" "}
                                      Denied
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </p>
                                <p className="imgparahe">
                                  Company
                                  <span className="imgparabody">
                                    {items.BusinessName}
                                  </span>
                                </p>
                                <p className="imgparahe">
                                  Job Title
                                  <span className="imgparabody">
                                    {items.title}
                                  </span>
                                </p>
                                <p className="imgparahe">
                                  Salary
                                  <span className="imgparabody">
                                    {items.salary}
                                  </span>
                                </p>

                                {applicantdetails[0] &&
                                applicantdetails[0].approved == null ? (
                                  <div className="d-flex">
                                    <button
                                      type="submit"
                                      className="btnbookr "
                                      onClick={() =>
                                        this.openModalUpdated(items, i)
                                      }
                                    >
                                      Approve
                                    </button>
                                    <button
                                      className="btnbookr "
                                      onClick={() =>
                                        this.openModalSucess(items, i)
                                      }
                                    >
                                      Deny
                                    </button>
                                  </div>
                                ) : (
                                  ""
                                )}

                                <div className="schoolModal">
                                  <Modal
                                    show={
                                      this.state.openSucess &&
                                      this.state.currentModalIndex == i
                                    }
                                  >
                                    <Modal.Body className="Madl">
                                      <div>
                                        <p
                                          className="closeModal"
                                          onClick={this.closeSuucess}
                                        >
                                          <i className="fas fa-times"></i>
                                        </p>
                                      </div>
                                      <div>
                                        <p className="succpar"> Hey Admin! </p>
                                        <p className="superball">
                                          Are you sure you want to disactivate
                                          the user!
                                        </p>
                                        <div>
                                          <div className="d-flex">
                                            <div className="d-flex">
                                              <label className="lebelenter">
                                                First Name:
                                              </label>
                                              <input
                                                className="formModalalt"
                                                name="res1"
                                                type="text"
                                                onChange={(e) =>
                                                  this.handleChange(e)
                                                }
                                                value={this.state.fields.res1}
                                              />
                                            </div>

                                            <br />
                                            <div className="d-flex">
                                              <label className="lebelenter">
                                                Last Name:
                                              </label>
                                              <input
                                                className="formModalalt"
                                                name="res2"
                                                type="text"
                                                onChange={(e) =>
                                                  this.handleChange(e)
                                                }
                                                value={this.state.fields.res2}
                                              />
                                            </div>
                                          </div>

                                          <br />
                                          <div className="d-block  ml-5">
                                            <p>{this.state.errormessage}</p>
                                            <div className="row">
                                              <div className="col-3"></div>
                                              <div className="col-6">
                                                <button
                                                  className="viewdetcli"
                                                  method="post"
                                                  onClick={(e) =>
                                                    this.submitHandler(e)
                                                  }
                                                >
                                                  Deny
                                                </button>
                                              </div>
                                              <div className="col-1"></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Modal.Body>
                                  </Modal>
                                </div>
                                <div className="schoolModal">
                                  <Modal
                                    show={
                                      this.state.openUpdate &&
                                      this.state.currentModalIndex == i
                                    }
                                  >
                                    <Modal.Body className="Madl">
                                      <div>
                                        <div className="detmod">
                                          <div>
                                            <p
                                              className="closeModale"
                                              onClick={this.closeUpdate}
                                            >
                                              <i className="fas fa-times"></i>
                                            </p>
                                          </div>
                                          <div className=" conteinputitle ">
                                            Update User
                                          </div>
                                          <div className="d-flex">
                                            <div className="conteinpu ml-3 d-flex">
                                              <p className=" claseleee">
                                                First Name:
                                              </p>
                                              <span
                                                className="unity  ml-2"
                                                onChange={(e) =>
                                                  this.handleChange(e)
                                                }
                                              >
                                                {this.state.fields.res1}
                                              </span>
                                            </div>
                                            <div className="conteinpu ml-3 d-flex">
                                              <p className="claseleee ">
                                                Last Name:
                                              </p>
                                              <span
                                                className="unity ml-2"
                                                onChange={(e) =>
                                                  this.handleChange(e)
                                                }
                                              >
                                                {this.state.fields.res2}
                                              </span>
                                            </div>
                                          </div>

                                          <div className="d-block  ml-5">
                                            <p>{this.state.errormessage}</p>
                                            <div className="row">
                                              <div className="col-3"></div>
                                              <div className="col-6">
                                                <button
                                                  type="submit"
                                                  className="viewdetcli"
                                                  method="post"
                                                  onClick={(e) =>
                                                    this.submitHandlerUpdate(e)
                                                  }
                                                >
                                                  Approve
                                                </button>
                                              </div>
                                              <div className="col-1"></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Modal.Body>
                                  </Modal>
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}

export default Jobapplications;
